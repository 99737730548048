<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.creditDateFrom.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.creditDateTo.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyID.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>MER Type</label>
                <select class="cdi_select form-control" v-model="state.optionFields.remakeTypeID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLookupID, remakeType] in cache.trackerCache.TRACKER_MER_REMAKE_TYPES" v-bind:key="cdiLookupID" :value="cdiLookupID">{{remakeType}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="cdi_select form-control" v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Regional Sales Rep</label>
                <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.salesUserID.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Sales Support Rep</label>
                <Typeahead cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.insideSalesUserID.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
              <label>National Sales Team</label>
              <select class="form-control" v-model="state.optionFields.nationalSalesTeams.val">
                <option value="0"></option>
                <option v-for="[id, location] in nationalSalesTeams" v-bind:key="id" :value="id">{{ location }}</option>
              </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type="checkbox" checked :true-value="1" :false-value="0" v-model="state.optionFields.openOnly.val"> Open Only</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-bind:class="{ toggle_hide: toggleHide }">
                <label><input type="checkbox" checked :true-value="1" :false-value="0" v-model="state.optionFields.closedOnly.val"> Closed Only</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-bind:class="{ toggle_hide: toggleHide }">
                <label><input type="checkbox" checked :true-value="1" :false-value="0" v-model="state.optionFields.enabledOnly.val"> Enabled Only</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-bind:class="{ toggle_hide: toggleHide }">
                <label>Special Tag</label>
                <select class="cdi_select form-control" v-model="state.optionFields.specialTag.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLookupID, userSpecialTag] in cache.userCache.USER_SPECIAL_TAGS" v-bind:key="cdiLookupID" :value="cdiLookupID">{{userSpecialTag}}</option>
                </select>
            </div>
            <div class="col-xs-12"><button type="button" @click="toggleMoreFilters" class="btn btn-default btn-sm">{{ toggleHide ? 'More' : 'Less' }} Filters</button></div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import trackerCache from '@/cache/tracker.cache.js';
    import userCache from '@/cache/user.cache.js';
    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "Options",
        components: { Typeahead, DatePicker },
        data() {
            return {
                state: store.state,
                toggleHide: store.state.toggleHide,
                cache: {
                    cdiLocationCache,
                    trackerCache,
                    userCache,
                },
              nationalSalesTeams: [
                [0,'Options Not Available']],
            }
        },
      created() {
        this.get_national_sales_teams();
      },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
            },
          get_national_sales_teams: function() {
            store.customAPI('menu_builder/national_sales_teams').then(res => this.nationalSalesTeams = res);
          }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>