<template>
    <div>
        <h1>Credits</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Options from './credit_rpt_opt.vue'
    import GroupBy from './utils/GroupBy.vue'
    import Fields from './utils/Fields.vue'
    import Results from "./utils/Results";

    export default {
        name: "credit_rpt",
        components: {
            Options,
            GroupBy,
            Fields,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    creditDateFrom: {val: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString(), qsField: '[dates][credit_date_from]'},
                    creditDateTo: {val: new Date().toLocaleDateString(), qsField: '[dates][credit_date_to]'},
                    companyID: {val: '', qsField: '[numbers][cr.company_id]'},
                    remakeTypeID: {val: 0, qsField: '[numbers][remake_type_id]'},
                    cdiLocationID: {val: 0, qsField: '[numbers][cr.cdi_location_id]'},
                    salesUserID: {val: 0, qsField: '[numbers][cr.sales_user_id]'},
                    insideSalesUserID: {val: 0, qsField: '[numbers][cr.inside_sales_user_id]'},
                    nationalSalesTeams: {val: 0, qsField: '[numbers][n.national_sales_team_id]'},
                    openOnly: {val: 1, qsField: '[isnot][payment_id]'},
                    closedOnly: {val: 0, qsField: '[isset][payment_id]', defaultVal: 0},
                    enabledOnly: {val: 1, qsField: '[isnot][credit_disable]', defaultVal: 1},
                    specialTag: {val: 0, qsField: '[numbers][d.branch_id]', defaultVal: 0},
                },
                groupByFields: {
                    credit_type_id: { label: 'Credit Type' },
                    remake_type_id: { label: 'MER Type' },
                    company_name: { label: 'Company' },
                    cdi_location: { label: 'Location' },
                    outside_sales: { label: 'Regional Sales Rep' },
                    inside_sales: { label: 'Sales Support Rep' }
                },
                dataFields: {
                    credit_id: { label: 'Credit ID', checked:false },
                    credit_date: { label: 'Date', checked: true },
                    credit_type_id: { label: 'Type', checked: true },
                    remake_type_id: { label: 'MER Type', checked: true },
                    cdi_location: { label: 'Location', checked: true },
                    company_name: { label: 'Company', checked: true },
                    tracker_id: { label: 'Order',checked:false },
                    outside_sales: { label: 'Regional Sales Rep',checked:false },
                    inside_sales: { label: 'Sales Support Rep', checked:false },
                    national_sales_team: {label: 'National Sales Team'},
                    credit_po: { label: 'Credit PO', checked: true },
                    credit_amount: { label: 'Amount', checked: true },
                    payment_id: { label: 'Payment ID', checked:false },
                    job_tag: { label: 'Job Tag', checked:false },
                    credit_disable: { label: 'Disabled', checked:false }
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Credits']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>